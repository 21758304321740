<template>
  <Page color="info" title="Mensa">
    <div>
      <v-container class="my-4">
        <v-row class="align-center justify-center">
          <v-btn outlined text @click="setToday" class="mr-2">Heute</v-btn>

          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                icon
                @click="prev()"
                v-if="!$vuetify.breakpoint.mobile"
              >
                <v-icon>mdi-chevron-left</v-icon>
              </v-btn>
            </template>
            <span>früher</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                icon
                @click="next()"
                v-if="!$vuetify.breakpoint.mobile"
              >
                <v-icon>mdi-chevron-right</v-icon>
              </v-btn>
            </template>
            <span>später</span>
          </v-tooltip>

          <v-menu
            v-model="dateMenu"
            offset-y
            :nudge-left="40"
            :close-on-content-click="false"
            transition="scale-transition"
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn outlined text v-bind="attrs" v-on="on" class="mr-4">
                {{ formatDate(focus, true) }}</v-btn
              >
            </template>

            <v-date-picker
              color="primary"
              v-model="focus"
              locale="de-CH"
              first-day-of-week="1"
              show-week
              :locale-first-day-of-year="4"
              @input="dateMenu = false"
            >
              <v-spacer /><v-btn
                text
                @click="
                  setToday();
                  dateMenu = false;
                "
                >heute</v-btn
              >
            </v-date-picker>
          </v-menu>
        </v-row>
      </v-container>

      <v-card :loading="loading" v-if="mensa">
        <v-system-bar>{{
          formatDate(start, true) + " – " + formatDate(end, true)
        }}</v-system-bar>
        <v-calendar
          locale="de-CH"
          ref="calendar"
          v-model="focus"
          color="primary"
          type="week"
          :events="events"
          :weekdays="'1,2,3,4,5,6,0'"
          first-interval="6:00"
          interval-count="18"
          @click:event="clickEvent"
        >
          <template
            v-slot:event="{
              event,
              eventParsed,
              day,
              eventSummary,
              timeSummary,
            }"
          >
            <div class="px-1">
              <template v-if="event.reservable"
                ><v-row no-gutters
                  ><v-col>{{ timeSummary() }}</v-col>

                  <v-col cols="auto"
                    ><v-icon color="white" small>mdi-plus</v-icon>
                  </v-col></v-row
                ></template
              >
              <template v-else>
                <v-row no-gutters
                  ><v-col>
                    <span>
                      <component
                        v-if="
                          eventParsed.start.date == day.date &&
                          eventParsed.end.date == day.date
                        "
                        :is="{ render: eventSummary }"
                      ></component>
                      <template v-else>
                        <strong>{{ eventParsed.input.name }}</strong
                        ><br />
                        <span v-if="weekView"
                          >{{ formatDayOfWeek(event.start) }}
                          {{ event.startTime }} -
                          {{ formatDayOfWeek(event.end) }}
                          {{ event.endTime }}</span
                        >
                        <span
                          v-if="
                            !weekView &&
                            eventParsed.start.date == day.date &&
                            eventParsed.end.date != day.date
                          "
                          >ab {{ eventParsed.start.time }} (bis
                          {{ formatDate(eventParsed.end.date, true) }},
                          {{ event.endTime }})
                        </span>
                        <span
                          v-if="
                            !weekView &&
                            eventParsed.start.date != day.date &&
                            eventParsed.end.date == day.date
                          "
                          >bis {{ eventParsed.end.time }}
                          (ab
                          {{ formatDate(eventParsed.start.date, true) }},
                          {{ event.startTime }})</span
                        >
                        <span
                          v-if="
                            !weekView &&
                            eventParsed.start.date != day.date &&
                            eventParsed.end.date != day.date
                          "
                          >{{ formatDate(eventParsed.start.date, true) }} bis
                          {{ formatDate(eventParsed.end.date, true) }}
                        </span>
                      </template>
                    </span>
                  </v-col>

                  <v-col cols="auto" v-if="event.deletable"
                    ><v-icon color="white" small>mdi-trash-can</v-icon>
                  </v-col></v-row
                >
              </template>
            </div>
          </template>
        </v-calendar>
      </v-card>
    </div>
    <v-dialog v-model="dialog" max-width="430" v-if="reservation">
      <v-card>
        <v-system-bar window>
          neue Reservation <v-spacer></v-spacer
          ><v-btn icon @click="dialog = false"
            ><v-icon>mdi-close</v-icon></v-btn
          >
        </v-system-bar>

        <v-list>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-subtitle>Zimmer</v-list-item-subtitle>
              <v-list-item-title>{{
                reservation.room ? reservation.room.description : "–"
              }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-subtitle>Tag</v-list-item-subtitle>
              <v-list-item-title
                ><DateValue long :value="reservation.date"></DateValue
              ></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <TimeInput
              :label="'von (frühestens ' + reservationWindow.min + ')'"
              :min="reservationWindow.min"
              :max="reservation.endTime"
              v-model="reservation.startTime"
            ></TimeInput>
          </v-list-item>
          <v-list-item>
            <TimeInput
              :label="'bis (spätestens ' + reservationWindow.max + ')'"
              :min="reservation.startTime"
              :max="reservationWindow.max"
              v-model="reservation.endTime"
            ></TimeInput>
          </v-list-item>
          <v-list-item>
            <v-text-field
              hideDetails
              label="Beschreibung"
              v-model="reservation.description"
            ></v-text-field>
          </v-list-item>
        </v-list>

        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn text @click="dialog = false"> Abbrechen </v-btn>

          <v-btn color="primary" text @click="saveReservation()">
            speichern
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </Page>
</template>

<script>
import { defineComponent } from "vue";
import { addDays, findMonday, formatDate, today } from "common/utils/date.js";
import { personCode } from "common/utils/people.js";

import DateValue from "common/components/DateValue.vue";
import TimeInput from "common/components/TimeInput.vue";

export default defineComponent({
  name: "Reservations",
  components: { DateValue, TimeInput },
  data() {
    return {
      focus: "",
      days: [],
      dateMenu: false,
      dialog: false,
      loading: false,
      search: "",
      events: [],
      reservation: {},
      mensa: { id: 54 },
      start: {},
      end: {},
      timeWindow: {
        min: "06:00",
        max: "23:30",
      },
      reservationWindow: {
        min: "06:00",
        max: "23:30",
      },
    };
  },
  watch: {
    focus() {
      this.fetchReservations();
      window.localStorage.setItem("roomReservationDate", this.focus);
    },
  },
  computed: {
    todayTime() {
      return (
        new Date(new Date().getTime() + 15 * 60 * 1000)
          .toLocaleTimeString("de-CH")
          .substring(0, 4) + "0"
      );
    },
  },
  methods: {
    formatDate,
    setToday() {
      this.focus = today();
    },
    prev() {
      this.$refs.calendar.prev();
    },
    next() {
      this.$refs.calendar.next();
    },
    async fetchReservations() {
      if (!this.focus) {
        return;
      }

      this.start = findMonday(this.focus);
      this.end = addDays(this.start, 6);

      this.days = [];
      let day = this.start;
      while (day <= this.end) {
        this.days.push(day);
        day = addDays(day, 1);
      }

      const now = new Date();
      this.loading = true;

      const events = [];
      const reservations = [];

      const roomReservations = await this.apiList({
        resource: "register/reservation",
        query: `startDate=${this.start}&endDate=${this.end}&room=${this.mensa.id}`,
      });

      for (const item of roomReservations) {
        reservations.push(item);
        const eventStart = item.startTime
          ? new Date(item.startDate + "T" + item.startTime)
          : new Date(item.startDate);
        const eventEnd = item.endTime
          ? new Date(item.endDate + "T" + item.endTime)
          : new Date(item.endDate);
        item.start = eventStart;
        item.end = eventEnd;
        const mine = this.$_isPerson(item.reservedFor);
        events.push({
          id: item.id,
          name: item.description
            ? item.description + " (" + personCode(item.reservedFor) + ")"
            : personCode(item.reservedFor),
          room: item.room,
          start: eventStart,
          end: eventEnd,
          startTime: item.startTime,
          endTime: item.endTime,
          timed: true,
          color: mine ? "primary" : "grey",
          deletable: mine && now < eventEnd.getTime(),
        });
      }

      for (const day of this.days) {
        let startTime = day == today() ? this.todayTime : this.timeWindow.min;

        let endTime = this.timeWindow.max;

        const dayReservations = roomReservations
          .filter((el) => el.startDate == day)
          .sort((a, b) => a.startTime.localeCompare(b.startTime));

        for (const dayReservation of dayReservations) {
          endTime = dayReservation.startTime;
          const start = new Date(day + "T" + startTime);
          const end = new Date(day + "T" + endTime);

          if (now < end && startTime != endTime) {
            events.push({
              name: "frei",
              start: start, //date
              end: end,
              date: day,
              startTime: startTime, //str
              endTime: endTime,
              room: this.mensa,
              timed: true,
              color: "success",
              reservable: true,
            });
          }
          startTime = dayReservation.endTime;
        }
        const start = new Date(day + "T" + startTime);
        const end = new Date(day + "T" + this.timeWindow.max);
        if (now < end && startTime != this.timeWindow.max) {
          events.push({
            name: "frei",
            start: start, //date
            end: end,
            date: day,
            startTime: startTime, //str
            endTime: this.timeWindow.max,
            room: this.mensa,
            timed: true,
            color: "success",
            reservable: true,
          });
        }
      }
      this.events = events;
      this.loading = false;
    },
    async saveReservation() {
      await this.apiPost({
        resource: "register/reservation",
        data: {
          reservedFor: this.$_profilePerson.id,
          room: this.reservation.room,
          startDate: this.reservation.date,
          startTime: this.reservation.startTime,
          endDate: this.reservation.date,
          endTime: this.reservation.endTime,
          description: this.reservation.description,
        },
      });
      this.dialog = false;
      this.fetchReservations();
    },
    async clickEvent({ event }) {
      if (event.reservable) {
        this.reservationWindow = {
          min: event.startTime,
          max: event.endTime,
        };
        this.reservation = {
          room: event.room,
          date: event.date,
          startTime: event.startTime,
          endTime: event.endTime,
          description: "",
        };
        this.dialog = true;
      }
      if (event.deletable) {
        if (
          await this.$root.confirm({
            message: "Soll diese Reservation gelöscht werden?",
            color: "danger",
            icon: "mdi-trash-can",
          })
        ) {
          await this.apiDelete({
            resource: "register/reservation",
            id: event.id,
          });
          this.fetchReservations();
        }
      }
    },
    async fetchData() {
      this.loading = true;
      this.mensa = await this.apiGet({
        resource: "common/room",
        id: 54,
      });
      this.loading = false;
    },
  },
  async created() {
    await this.fetchData();
  },
  mounted() {
    if (window.localStorage.getItem("roomReservationDate")) {
      this.focus = window.localStorage.getItem("roomReservationDate");
    } else {
      this.setToday();
    }
  },
});
</script>
